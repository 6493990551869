export default {
  props: {
    photo: {
      type: Object,
      default: null,
    },
    type: String,
  },
  data: () => ({
    bottomSheetActive: false,
  }),
  computed: {
    immediatelyUrl() {
      if(!this.photo) return null
      if(this.photo.url) return this.photo.url
      if(!this.photo.crop) return this.photo.size_list.origin.url
      return null
    },
    immediatelyPhoto() {
      if(!this.photo) return null
      if(this.photo.url) return this.photo
      if(!this.photo.crop) return this.photo.size_list.origin
      return null
    },
  },
  components: {
    photoSizeListSelection: () => import('./_photoSizeListSelection.vue'),
  },
}